import { useParams } from 'react-router-dom';
import styled from "styled-components";
import { useState, useEffect } from 'react';
import axios from 'axios';

const OfferDetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 80px 160px;
    padding-bottom: 2rem;
    min-height: 600px;

    @media (max-width: 1024px) {
        margin: 60px 80px;
    }

    @media (max-width: 768px) {
        margin: 40px 20px;
    }
`;

const LeftSide = styled.div`

    h2{
        margin-top: 2rem;
        font-size: 2.4rem;
        font-weight: 700;
    }
`

const CouponImage = styled.div`
    width: 600px;
    height: 300px;
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 768px) {
        height: 140px;
    }
`

const RightSide = styled.div`

    h2{
        font-size: 2.4rem;
    }

    p{
        font-size: 1.1rem;
    }
`

const OptionsDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
`

const RedemptionsDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 50%;

    svg{
        width: 24px;
        height: 24px;
    }
    
    p{
        font-weight: 600;
    }
`

const ShareButton = styled.button`
    background-color: white;
    border-radius: 8px;
    border: 2px solid #008c9a;
    padding: 12px;
    width: 120px;
    font-size: 1.1rem;
    color: #008c9a;
    font-weight: 600;
`

const RedeemButton = styled.button`
    margin-left: auto;
    margin-right: auto;
    display: block;
    border: 2px solid #D4F4BB;
    outline: none;
    border-radius: 6px;
    background: linear-gradient(to top, #7AC143, #94E656);
    color: white;
    width: 100%;
    padding: 12px;
    font-weight: 600;
    font-size: .9rem;
    text-transform: uppercase;
    cursor: pointer;
    text-shadow: gray 1px 0 1px;
    margin-top: 2rem;
`

const OfferDetails = () => {
    const [details, setDetails] = useState();
    const { id } = useParams();

    const fetchDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offer/${id}`, {
            });

            setDetails(response?.data)
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, [])

    return (
        <OfferDetailsContainer>
            <LeftSide>
                <CouponImage img={details?.image_url} />
                <h2>
                    About {details?.title}
                </h2>
                <p>Some Text About This Company, But No Data From The API</p>
            </LeftSide>
            <RightSide>
                <h2>{details?.title}</h2>
                <p>{details?.description}</p>

                <OptionsDiv>
                    <RedemptionsDiv>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16l-97.5 0c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8l97.5 0c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32L0 448c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32l-64 0z" /></svg>
                        <p>{details?.redemptions} Redemptions</p>
                    </RedemptionsDiv>
                    <ShareButton>Share</ShareButton>
                </OptionsDiv>

                <RedeemButton><a target={details?.target} href={details?.link} rel="noreferer">Redeem This Offer</a></RedeemButton>
            </RightSide>
        </OfferDetailsContainer>
    );
};

export default OfferDetails;