import styled from "styled-components";

const TermsComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 80px 160px;

    h1{
        font-size: 3rem;
        font-family: 'Times New Roman', Times, serif !important;
    }

    h2{
        font-size: 1.8rem;
        color: lightgray;
        font-family: 'Times New Roman', Times, serif !important;
    }

    p{
        line-height: 1.7rem;
        font-family: 'Times New Roman', Times, serif !important;
    }

    @media (max-width: 1024px) {
        margin: 60px 80px;
    }

    @media (max-width: 768px) {
        margin: 40px 20px;
    }
`

const TermsOfUse = () => {
    return (
        <TermsComponent>
            <h1>Terms Of Use</h1>

            <h2>Updated 9/18/2024</h2>

            <p>
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
            </p>
        </TermsComponent>
    )
}

export default TermsOfUse;