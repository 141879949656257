import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OfferDetails from "./pages/OfferDetails";
import { useDispatch, useSelector } from "react-redux";
import { setUuid } from "./redux/features/uuidSlice";

function App() {
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", type: "" }); //success or warning or info or error for the TYPE
  const [location, setLocation] = useState({ lat: null, long: null });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uuidFromUrl = searchParams.get('t');

    if (uuidFromUrl) {
      dispatch(setUuid(uuidFromUrl))
    }
  }, []);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLocation({
  //           lat: position.coords.latitude,
  //           long: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.log(error)
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header setSnackbar={setSnackbar} location={location} />
        <Routes>
          <Route path="/" element={<Home setSnackbar={setSnackbar} location={location} />} />
          <Route path="/about" element={<About setSnackbar={setSnackbar} />} />
          <Route path="/contact" element={<Contact setSnackbar={setSnackbar} />} />
          {/* <Route path="/signup" element={<Signup setSnackbar={setSnackbar} />} />
          <Route path="/login" element={<Login setSnackbar={setSnackbar} />} />
          <Route path="/forgot-password" element={<ForgotPassword setSnackbar={setSnackbar} />} />
          <Route path="/reset-password" element={<ResetPassword setSnackbar={setSnackbar} />} /> */}
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/offer/:id" element={<OfferDetails />} />
        </Routes>
        <Footer setSnackbar={setSnackbar} />
      </BrowserRouter>

      {snackbar.isOpen &&
        <Snackbar
          open={snackbar.isOpen}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, isOpen: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ width: '400px' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, isOpen: false })}
            severity={snackbar.type}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert></Snackbar>}
    </div>
  );
}

export default App;
