import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    uuid: null
};

export const uuidSlice = createSlice({
    name: "uuid",
    initialState,
    reducers: {
        setUuid: (state, action) => {
            state.uuid = action.payload;
        },
    },
});

export const { setUuid } = uuidSlice.actions;

export default uuidSlice.reducer;