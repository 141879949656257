import styled from "styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState } from "react";

const OffersContainer = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0px 160px;

    @media (max-width: 1024px) {
        padding: 0px 80px;
    }

    @media (max-width: 768px) {
        padding: 0px 20px;
    }
`

const OffersNav = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2{
        font-size: 2.5rem;
        font-weight: 800;
        color: #3D3D3D;

        @media (max-width: 1024px) {
            font-size: 1.6rem;
        }

        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }
`

const OffersListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
`;

const SingleOffer = styled.div`
    cursor: pointer;
    flex-basis: calc(33% - 2rem);
    border-radius: 12px;
    border: 1px solid #ccc;
    min-width: 250px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 20px 12px;
    
    @media (max-width: 1024px) {
        flex-basis: calc(50% - 2rem);
    }

    @media (max-width: 768px) {
        flex-basis: calc(100%);
    }
`;

const SingleOfferTitle = styled.h2`
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    height: 70px;
    padding: 20px 0;
`

const SingleOfferAdd = styled.div`
  width: 96%;
  height: 275px;
  margin: 1rem auto auto auto;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 3px 5px rgba(0,0,0,.25);

  @media (max-width: 768px) {
    background-size: contain;
    height: calc(55vw - .5rem);
  }  

`;

const SingleOfferRedemptions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 97%;
    justify-content: left;
    padding: .75rem .5rem;

    svg{
        width: 26px;
        height: 26px;
        cursor: pointer;
    }

    p{
        font-size: 1rem;
        font-weight: 700;
    }
`

const SingleOfferButton = styled.button`
    margin: 0 auto;
    display: block;
    border: 3px solid #D4F4BB;
    background: linear-gradient(to top, #7AC143, #94E656);
    outline: none;
    border-radius: 10px;
    color: white;
    width: 98%;
    padding: 14px;
    font-weight: 800;
    font-size: 1.1rem;
    text-transform: uppercase;
    cursor: pointer;
`

// const FindNewOffer = styled.div`
//     flex-basis: calc(25% - 2rem);
//     border-radius: 12px;
//     border: 4px dashed grey;
//     min-width: 250px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center; 
//     align-items: center;
//     padding: 1rem;

//     @media (max-width: 1024px) {
//         flex-basis: calc(50% - 2rem);
//     }

//     @media (max-width: 768px) {
//         flex-basis: calc(100% - 2rem);
//     }

//     input {
//         padding: 12px;
//         margin-top: 2rem;
//         border: 1px solid #eee;
//     }

//     svg{
//         width: 72px;
//         height: 72px;
//         fill: #008c9a;

//         @media (max-width: 768px) {
//             width: 54px;
//             height: 54px;
//         }
//     }

//     p{
//         font-weight: 600;
//         font-size: 2rem;
//         text-align: center;
//         margin-top: 1rem;

//         @media (max-width: 768px) {
//             font-size: 1.2rem;
//         }
//     }
// `

const PrevNextContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 2rem;
`

const PrevNextButton = styled.button`
    width: fit-content;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 12px;
    background-color: #8ac926;
    color: white;
    padding: 12px;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-top: 1rem;
    border: 2px solid #D4F4BB;
    background: linear-gradient(to top, #7AC143, #94E656);
`

const SingleOfferLogo = styled.div`
  width: 40%;
  height: 60px;
  margin: 1rem auto auto auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

    @media (max-width: 768px) {
        width: 45%;
        margin: 0 auto;
    }

`;

const OffersList = ({ offers, offersCount, setSnackbar, setFetchAgain, setLoading, loading, handleOpenModal }) => {
    const auth = useSelector((state) => state?.auth?.user);

    const handleLike = async (offerId) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/offers/${offerId}/like`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );

            setSnackbar({
                isOpen: true,
                message: "Offer Liked!",
                type: "success"
            });
            setFetchAgain(true)
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleDisslike = async (offerId) => {
        setLoading(true)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/offers/${offerId}/unlike`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                }
            );

            setSnackbar({
                isOpen: true,
                message: "Offer Unliked!",
                type: "success"
            });
            setFetchAgain(true)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <OffersContainer>
            {loading ? <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "600px" }}>
                <CircularProgress />
            </Box> : <>
                <OffersNav>
                    <h2>{offersCount} Exclusive Offers For You!</h2>

                </OffersNav>

                <OffersListContainer>
                    {offers?.map((data, index) => (
                        <SingleOffer onClick={() => handleOpenModal(data)} key={index}>
                            <SingleOfferLogo style={{
                                backgroundImage: `url(${data?.client?.logo_url})`
                            }} />
                            <SingleOfferAdd
                                style={{
                                    backgroundImage: `url(${data?.image_url})`
                                }}
                            />
                            <SingleOfferRedemptions>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16l-97.5 0c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8l97.5 0c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32L0 448c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32l-64 0z" /></svg>

                                <p>{data?.redemptions} Redemptions</p>
                            </SingleOfferRedemptions>

                            <SingleOfferButton onClick={() => handleOpenModal(data)}>Redeem This Offer</SingleOfferButton>

                            {/* {auth?.token && <SingleOfferOptions>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z" /></svg>
                                    <p style={{ color: "#6c757d" }}>Add to Favorites</p>
                                </div>
                            </SingleOfferOptions>} */}

                        </SingleOffer>
                    ))}

                    {/* <FindNewOffer>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8l176 0 0 176c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" /></svg>
                        <p>Find More Offers Near You!</p>

                        <input placeholder="Enter Zip Code" />
                    </FindNewOffer> */}

                </OffersListContainer>

                {/*        
                <PrevNextContainer>
                    {page === 1 ? null : <PrevNextButton onClick={handlePrevPage} disabled={page === 1}>
                        Previous Page
                    </PrevNextButton>}

                    {offers?.length === 10 &&
                        <PrevNextButton onClick={handleNextPage}>
                            Next Page
                        </PrevNextButton>}
                </PrevNextContainer>
                */}

            </>}

        </OffersContainer>
    )
}

export default OffersList;