import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    user: null
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        registerUser: (state, action) => {
            state.user = action.payload;
        },
        loginUser: (state, action) => {
            state.user = action.payload
        },
        logoutUser: (state) => {
            state.user = null;
        }
    },
});

export const { registerUser, loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;