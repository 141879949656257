import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const BannerContainer = styled.div`
    display: flex;
    gap: 1rem;
    height: 350px;
    background-color: #48cae4;
    margin-top: 8rem;

    @media (max-width: 1359px) {
        height: 300px;
    }

    @media (max-width: 768px) {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
`

const BannerRightContent = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;

    @media (max-width: 1024px) {
        align-items: center;
    }

    h2 {
        color: white;
        font-weight: 600;
        text-align: center;
        font-size: 3rem;

        @media (max-width: 1359px) {
            font-size: 2.4rem;
        }

        @media (max-width: 1024px) {
            font-size: 2.2rem;
        }

        @media (max-width: 768px) {
            font-size: 1.6rem;
        }
    }

    p{
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;

        @media (max-width: 1359px) {
            font-size: 1.05rem;
        }

        @media (max-width: 1024px) {
            font-size: 1rem;
        }

        @media (max-width: 768px) {
            font-size: .8rem;
        }
    }

    button{
        cursor: pointer;
        padding: 10px;
        border: none;
        border-radius: 8px;
        outline: none;
        background-color: white;
        color: #009FC2;
        font-size: 1.1rem;
        font-weight: 800;
        min-width: 180px;
        text-transform: uppercase;

        @media (max-width: 1359px) {
            font-size: 1rem;
        }

        @media (max-width: 768px) {
            font-size: .9rem;
        }
    }
`

const BannerImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    position: relative;

    @media (max-width: 1024px) {
        display: none;
    }
`

const BannerImage = styled.img`
    width: 600px;
    height: 450px;
    position: absolute;
    top: 55%;
    left: 55%;
    transform: translate(-50%, -50%);

    @media (max-width: 1359px) {
        width: 500px;
        height: 400px;
    }
`

const SpikesImage = styled.img`
    width: 70px;
    height: 350px;
    position: absolute;
    left: 0%;

    @media (max-width: 1359px) {
        height: 300px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

const SpikeImageRight = styled.img`
    width: 70px;
    height: 350px;
    position: absolute;
    right: 0%;

    @media (max-width: 1359px) {
        height: 300px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

const Banner = () => {
    const navigate = useNavigate();

    return (
        <BannerContainer>
            <SpikesImage src="/spike-border.png" />
            <BannerImageContainer>
                <BannerImage src="/banner_image.png" />
            </BannerImageContainer>
            <BannerRightContent>
                <h2>Big Move, Big Savings!</h2>
                <p>Personalize Offers For Me</p>

                <button onClick={() => navigate("/contact")}>Contact Us</button>
            </BannerRightContent>
            <SpikeImageRight src="/spike-border_right.png" />
        </BannerContainer>
    )
}

export default Banner;